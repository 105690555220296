import React from 'react'
import { Section, ImageBasic, Grid } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import Helmet from 'react-helmet'
import { withNamespaces } from 'react-i18next'

import Config from '../../Config'
import Classes from '../../styles/classes'

const styles = {
  image: {
    borderRadius: '0.5em'
  },
  bold: {
    fontWeight: 'bold'
  }
}
const RegleDutilisation = ({ t }) => (
  <React.Fragment>
    <Helmet
      title={t('head.title')}
      meta={[
        {
          name: 'description',
          content: t('head.meta-description')
        }
      ]}
    />
    <Section className={css(Classes.section)}>
      <h2 className={css(Classes.h2)}>{t('sections.0.titre')}</h2>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('sections.0.paragraphes.0')
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('sections.0.paragraphes.1')
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('sections.0.paragraphes.2')
        }}
      />
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('sections.0.paragraphes.3')
        }}
      />
    </Section>
    <Section className={css(Classes.section)}>
      <h2 className={css(Classes.h2)}>{t('sections.1.titre')}</h2>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('sections.1.paragraphes.0')
        }}
      />
      <ul>
        <li
          dangerouslySetInnerHTML={{
            __html: t('sections.1.paragraphes.1')
          }}
        />
        <li
          dangerouslySetInnerHTML={{
            __html: t('sections.1.paragraphes.2')
          }}
        />
      </ul>
      <p
        className={css(Classes.paragraph)}
        dangerouslySetInnerHTML={{
          __html: t('sections.1.paragraphes.3')
        }}
      />
    </Section>
  </React.Fragment>
)

export default withNamespaces('regle-d-utilisation')(RegleDutilisation)
